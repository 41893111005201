import L from 'leaflet';
import {ImageTransformLayer} from '~/lib/leaflet.layer.ImageTransform';

import '~/lib/leaflet.layer.soviet-topomaps-grid';
import {LatviaTopoGrid} from '~/lib/leaflet.layer.latvia-topo-grid';
import {VesturesStastiLayer} from '~/lib/leaflet.layer.vesture';
import {RobezpunktiLayer} from '~/lib/leaflet.layer.robezpunkti';
import {DodiesLvLayer} from '~/lib/leaflet.layer.dodieslv';
import {sources} from '~/sources';

function anrijs_tiles(url, hostname = 'tiles.anrijs.lv', ext = 'png') {
    return `https://${hostname}/${url}/{z}/{x}/{y}.${ext}`;
}

function anrijs_quickLayer(title, years, url, code, maxZoom, options = {}) {
    return {
        title: title,
        isDefault: options.default || false,
        years: years,
        scale: options.scale || undefined,
        layer: L.tileLayer(url,
            {
                code: code,
                isOverlay: options.isOverlay || true,
                tms: options.tms || false,
                print: options.print || true,
                jnx: options.print || true,
                scaleDependent: options.scaleDependent || false,
                shortName: options.shortName || code,
                isOverlayTransparent: options.isOverlayTransparent || false,
                maxNativeZoom: maxZoom,
                minZoom: options.minZoom || 0,
                noOpacity: options.noOpacity || false,
                hotkey: options.hotkey || undefined,
                attribution: options.attribution || undefined,
                modkey: options.modkey || undefined,
                opacity: options.opacity || undefined,
          })
    };
}

const layersDefs = [
            {
                title: 'OpenStreetMap',
                group: 'Pamatnes',
                description: 'OSM default style',
                isDefault: true,
                layer: L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                    {
                        code: 'O',
                        isOverlay: false,
                        scaleDependent: true,
                        print: true,
                        jnx: true,
                        shortName: 'osm',
                        maxNativeZoom: 19,
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">' +
                            'OpenStreetMap</a> contributors'
                    }
                )
            },
            {
                title: 'ESRI Sat',
                group: 'Pamatnes',
                isDefault: true,
                layer: L.tileLayer(
                    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    {
                        code: 'E',
                        isOverlay: false,
                        scaleDependent: false,
                        maxNativeZoom: 18,
                        maxZoom: 21,
                        print: true,
                        jnx: true,
                        shortName: 'esri',
                        attribution:
                        '<a href="https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9">' +
                        'ESRI World Imagery for ArcGIS</a>',
                    }
                )
            },
            {
                title: 'Test Layer 1',
                isDefault: false,
                layer: new ImageTransformLayer(
                    'https://o.anrijs.lv/getmap.php?id=234',
                    [
                        [56.99598, 24.11773], [56.99596, 24.19146], [56.96754, 24.1915], [56.96752, 24.11781]
                    ],
                    {
                        isOverlay: true,
                        code: 'Y',
                        opacity: 1,
                        disableSetClip: true
                    }
                )
            },
            anrijs_quickLayer(
                'Magnēts', '2019', anrijs_tiles('o2019/magnets'),
                'Om', 18, {shortName: 'magnets2019'}
            ),
            anrijs_quickLayer(
                'Kāpa', '2019', anrijs_tiles('o2019/kapa'),
                'Ok', 18, {shortName: 'kapa2019'}
            ),
];

const groupsDefs = [
    {
        title: 'Pamatnes',
        collapse: true,
        layers: [
            'OpenStreetMap',
            'ESRI Sat',
        ],
    },
    {
        title: 'Orient',
        collapse: true,
        layers: [
            'Magnēts',
            'Kāpa',
        ]
    },
];

// last will be on top
const titlesByOrder = [

    // common base layers
    'OpenStreetMap',
    'ESRI Sat',

    '#custom-bottom',

    'Test Layer 1',

    'Magnēts',
    'Kāpa',

    '#custom-top',
];

function getLayers(tracklist) {
    // generate from sources
    const defaultOptions = {
        isOverlay: true,
        tms: false,
        print: true,
        jnx: true,
        scaleDependent: false,
        isOverlayTransparent: false,
        minZoom: 0,
        noOpacity: false,
        code: undefined,
        shortName: undefined,
        maxNativeZoom: undefined,
        hotkey: undefined,
        attribution: undefined,
        modkey: undefined,
        opacity: undefined,
    };

    const config = sources.config;

    for (let group of sources.groups) {
        let groupName = group.name;
        let layers = group.layers;
        let groupLayers = [];

        for (let layer of layers) {
            let title = layer.name;
            if (layer.years) {
                title = `${layer.years} ${title}`;
            }
            if (layer.scale) {
                title = `${title} ${layer.scale}`;
            }

            // Validate layer
            if (!layer.code) {
                if (!layer.name) {
                    continue;
                }
                throw new Error(`Missing code for layer ${title}`);
            }

            const type = layer.type || "TileLayer";
            const isDefault = layer.isDefault || false;
            let extras = {...defaultOptions, ...layer.extras || {}};

            extras.maxNativeZoom = layer.zoom || extras.maxNativeZoom;
            extras.code = layer.code || extras.code;
            extras.shortName = layer.shortName || extras.shortName || extras.code;

            let lyr = {
                title: title,
                isDefault: isDefault,
                name: layer.name,
                years: layer.years,
                scale: layer.scale,
                layer: undefined,
                bbox: extras.bbox
            };

            let url = layer.url;
            if (!/^(https?:\/\/|:\/\/)/u.test(url)) {
                url = `${config.base}${url}`;
            }

            switch (type) {
                case "TileLayer":
                    if (!layer.url) {
                        throw new Error(`Missing tiles url for layer ${title}`);
                    }

                    lyr.layer = L.tileLayer(url, extras);
                    break;
                case "WMS":
                    if (!url) {
                        throw new Error(`Missing tiles url for layer ${title}`);
                    }
                    lyr.layer = L.tileLayer.wms(url, extras);
                    break;
                case "DodiesLvLayer":
                    if (!url) {
                        throw new Error(`Missing tiles url for layer ${title}`);
                    }

                    lyr.layer = new DodiesLvLayer(url, extras);
                    break;
                case "RobezpunktiLayer":
                    if (!url) {
                        throw new Error(`Missing tiles url for layer ${title}`);
                    }

                    lyr.layer = new RobezpunktiLayer(url, extras);
                    break;
                case "LatviaTopoGrid":
                    lyr.layer = new LatviaTopoGrid(extras);
                    break;
                case "VesturesStastiLayer":
                    if (!url) {
                        throw new Error(`Missing tiles url for layer ${title}`);
                    }

                    lyr.layer = new VesturesStastiLayer(url, extras);
                    break;
                default:
                    throw new Error(`Unknown layer type: ${type}`);
            }

            if (lyr.layer) {
                layersDefs.push(lyr);
                titlesByOrder.splice(titlesByOrder.length - 1, 0, lyr.title);
                groupLayers.push(lyr.title);
            } else {
                throw new Error(`No layer created for: ${title}`);
            }
        }

        groupsDefs.push(
            {
                title: groupName,
                collapse: true,
                layers: groupLayers,
            }
        );
    }

    // set metadata
    for (let layer of layersDefs) {
        layer.layer.meta = {
            name: layer.name,
            title: layer.title,
            years: layer.years,
            scale: layer.scale,
            bbox: layer.bbox
        };
        layer.layer.tracklist = tracklist;
        layer.maxZoom = 21;
    }

    // assign order to layers
    const orderByTitle = {};
    for (let i = 0; i < titlesByOrder.length; i++) {
        let title = titlesByOrder[i];
        orderByTitle[title] = i + 1;
    }

    for (let layer of layersDefs) {
        const title = layer.title;
        layer.order = orderByTitle[title];
        if (!layer.order) {
            throw new Error(`Layer title not found in titlesByOrder list: ${title}`);
        }
    }

    // divide layers by groups
    const grouppedLayers = [];
    const layersByTitle = {};
    for (let layer of layersDefs) {
        layersByTitle[layer.title] = layer;
    }
    for (let groupDef of groupsDefs) {
        let collapse = groupDef.collapse === true;
        let group = {group: groupDef.title, collapse: collapse, layers: []};
        grouppedLayers.push(group);
        for (let title of groupDef.layers) {
            let layer = layersByTitle[title];
            if (!layer) {
                throw new Error(`Layer title not found in groupsDefs list: ${title}`);
            }
            layer.layer.group = groupDef.title;
            group.layers.push(layer);
        }
    }

    return {
        layers: grouppedLayers,
        customLayersOrder: {
            top: orderByTitle['#custom-top'],
            bottom: orderByTitle['#custom-bottom'],

        }
    };
}

export {getLayers, layersDefs, groupsDefs, titlesByOrder};
